<template>
  <div class="filter-tab py-md-0" :class="[ {'search-result': isSearchPage, 'center-position': mediaSize === 'large-size'}, mediaSize ]">
    <div class="container">
      <div class="row">
        <div class="col-12 filter-Wrap1">
          <div class="card search-wrapper">
            <SearchTab v-if="hotelList.data && destinationList.length > 0 && hotelList.data.length > 0" :breadcrumb="`breadcrumb`" :type="mediaSize === 'small-size' ? 'mobile' : 'tabs'"
                  @closeSearchMobile="closeSearchMobile" />
            <ContentLoading v-else type="search-tab"/>
            <div v-if="!hotelList.data || destinationList.length === 0 || hotelList.data.length === 0" class="loading-message">{{ $t('search-tab.loading-message') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateSearchTabs from './TemplateSearchTabs';

export default {
  name: 'SearchTabsTheme2',
  mixins: [TemplateSearchTabs],
  components: {
    SearchTab: () => import('../searchTab/SearchTabTheme2'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
};
</script>

<style scope lang="scss">
.filter-tab{
  position: relative !important;
  z-index: 10;

  .input-group {
    overflow: unset;
  }
}

.filter-tab.large-size{
  margin-top: 20px !important;

  .filter-Wrap1 {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    padding: 10px;
    width: 100%;
    margin: auto;
    max-width: 1544px;

    .search-wrapper{
      background: #FFFFFF;
      box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.06);
      border-radius: 15px;
      box-sizing: border-box;
      padding: 18px 15px
    }
  }
}

.filter-tab.small-size{
  margin-top: 0px !important;
  .search-wrapper{
    border-radius: 0px;
    border: 0px;
    background-color: transparent;
  }

  .loading-message{
    font-size: 1.3rem;
  }
}

</style>
